<template>

	<v-layout class="flex-column homescreen">
		<v-progress-linear v-show="loadingPage.show" slot="progress" color="red" indeterminate style="margin:0!important;"></v-progress-linear>

		<v-row>
			<v-col cols="12" sm="4" class="" style="margin-bottom: 4px !important;">
				<v-card class="pa-2 py-2 px-2 rounded-lg" style="margin-right: 10px; margin-bottom: 10px;" v-for="(x) in suaraPaslon" :key="x.id">
					<v-row>
						<v-col cols="12" sm="3" style="margin-bottom: 0px !important;">
							<v-img :lazy-src="noimage" :src="scimage+x.id" aspect-ratio="1" class="rounded-circle" max-height="80" max-width="80" />
						</v-col>
						<v-col cols="12" sm="6" style="margin-bottom: 0px !important;">
							<h4 class="mb-1">Paslon #{{ x.nourut  }}</h4>
							<p class="mb-0" style="font-size: 12px;">{{x.name1}}<br/>{{x.name2}}</p>
							<p class="mb-0">Perolehan suara: {{numberFormat(x.iVoters)}}</p>
						</v-col>
						<v-flex text-right>
							<a class="text-decoration-none" style="font-size: 12px;">Presentase</a>
							<p style="margin: auto;">
								<v-progress-circular :rotate="360" :size="60" :width="8" :value="x.porsen" :color="`${x.porsen>=50?'#15BF8C':x.porsen>=25&&x.porsen<50?'#FFB727':'#FF8186'}`">{{ x.porsen }}%</v-progress-circular>
							</p>
						</v-flex>
					</v-row>
				</v-card>
			</v-col>
			<v-col cols="12" sm="8" class="" style="margin-bottom: 4px !important;">
				<v-card class="pa-2 py-3 px-3 rounded-lg">
					<v-row>
						<v-col :sm="tingkatan=='Kecamatan'?12:8">
							<v-flex xs12 md12>
							<h4 class="mb-1">Suara Masuk <v-chip :color="`${tot_voters>=75?'#15BF8C':tot_voters>=25&&tot_voters<75?'#FFB727':'#FF8186'}`">{{ tingkatan }} - {{ tot_voters }}%</v-chip> Suara SAH 
								<v-chip color="#15BF8C">{{suaraPaslon.length>0?numberFormat(suaraPaslon[0].sah):0}}</v-chip> Tidak SAH 
								<v-chip color="#FFE6E7">{{suaraPaslon.length>0?numberFormat(suaraPaslon[0].notsah):0}}</v-chip>. Total Perhitungan Suara <v-chip color="#FFE6E7">{{suaraPaslon.length>0?(numberFormat(suaraPaslon[0].sah+suaraPaslon[0].notsah)):0}} (+{{ suaraPaslon.length>0?numberFormat(suaraPaslon[0].dptb):0 }})</v-chip> dari total DPT <v-chip>{{ suaraPaslon.length>0?numberFormat(suaraPaslon[0].dpt):0 }}</v-chip>
							</h4>
							</v-flex>
						</v-col>
						<v-col v-if="tingkatan!='Kecamatan'">
							<v-flex text-right>
								<a class="text-decoration-none" @click="backTingkatan()" title="BACK">{{ `<< ${lblDaerah}` }}</a>
							</v-flex>
						</v-col>
						<v-flex xs12 md12>
							<v-row class="mb-1">
								<v-flex xs12 md3 v-for="(x) in dataRekap" :key="x.id">
									<v-card class="px-2 py-2" outlined @click="setTingkatan(x.id)">
										<v-row>
											<v-col cols="12" sm="8" style="margin-bottom: 0px !important;">
												<h5>{{x.nama}}</h5>
												<p class="mb-0">
													<span :style="`color:${x.porsen>=0&&x.porsen<=25?'#FF8186':x.porsen==100?'#15BF8C':'#FFB727'}`">{{numberFormat(x.sah)}}</span> of <v-chip v-if="x.dptb>0" color="gray" title="Jumlah DPT" x-small>{{numberFormat(x.dpt)}} (+{{ numberFormat(x.dptb) }})</v-chip><v-chip v-else color="gray" title="Jumlah DPT" x-small>{{numberFormat(x.dpt)}}</v-chip>
												</p>
												<v-chip :color="`${x.notsah>0?'#FFE6E7':'#E3F9F2'}`" x-small>{{numberFormat(x.notsah)}} suara tidak sah</v-chip>
											</v-col>
											<v-flex text-right>
												<v-chip :color="`${x.porsen>=0&&x.porsen<=25?'#FF8186':x.porsen==100?'#15BF8C':'#FFB727'}`">{{x.porsen}}%</v-chip>
											</v-flex>
										</v-row>
									</v-card>
								</v-flex>
							</v-row>
						</v-flex>
					</v-row>
				</v-card>
			</v-col>
		</v-row>
		<!-- POPUP -->
        <v-main>
            <v-layout align-center justify-center style="border-radius:15px 15px 0px 0px">
                <v-dialog persistent v-model="body.showPopUp" scrollable width="1200" style="position: absolute">
                    <Detail v-if="body.showDetail" :modeEdit="true" :data="body.dataDetail" v-on:closePopUp="hFunc_closePupUp()" />
                </v-dialog>
            </v-layout>
        </v-main>
		<!-- FOOTER: API Version & Config -->
		<core-footer :shown="true" bgcolor="white" column="x12"></core-footer>
	</v-layout>
</template>

<script>
import { message } from "@/consistencychecker/formvalidator/message";
import { success, error } from "@/consistencychecker/transactionvalidator/index";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ValueRef from "@/services/sa/SA_ValueRef";
import { numberFormat } from "@/consistencychecker/formvalidator/format"
import Detail from './Detail.vue'

export default {
	components: {
		Loading, Detail
	},
	metaInfo() {
		return { title: "Quick Count" };
	},
	name: "HomeScreen",
	data() {
		return {
			loadingPage: {
				show: true,
				fullPage: true
			}, tot_voters:0.0,
			tingkatan:'Kecamatan',lblDaerah:'',selectedArea:[], 
			dataRekap:[], suaraPaslon:[],
			drawer: false,
			showArrows: true,
			isMobileScreen: window.screen.width >= 600,
			noimage: `${process.env.VUE_APP_BASE_URL}/noimage.png`,
			scimage: `${process.env.VUE_APP_END_GW}/candidates/`,
			listInfo: true,
			listCalender: true,
			header: {
				title: "Hi, Welcome to Website QuickCount",
				subtitle: "Monitoring kemenangan Rajiun - Purnama (RP)",
			},
			body: {
				bgImg: `${process.env.VUE_APP_BASE_URL}/bg.png`,
				versi_web: `WEB-V${process.env.VUE_APP_VERSION_WEB}`,
				versi_api: `API-V${process.env.VUE_APP_VERSION_API}`,
				loading: true,
				urlGW: process.env.VUE_APP_END_GW,
				dialog: false,
				submitdis: false,
				eg: process.env.VUE_APP_END_GW + process.env.VUE_APP_BASE_URL,
				dataPaslon: [], dataWilayah: [], dataSuara: [],
				showPopUp: false, showDetail: false, dataDetail:{}
			},
			footer: {
				webVersion: `WEB-V${process.env.VUE_APP_VERSION_WEB}`,
				apiVersion: `API-V${process.env.VUE_APP_VERSION_API}`,
			},
			dialog: {
				confirmChange: {
					shown: false,
					txtTittle: "Login",
					flagBody: true,
					txtBody: message.confirm[0](),
					flagBtn1: true,
					txtBtn1: "Ya",
					txtBtn2: "Tidak",
				},
			},
			showNotification: false,
		};
	},
	computed: {
		console: () => console,
		window: () => window,
	},
	created() {
        this.getDataPaslon();
		this.getDataWilayah();
		this.getDataSuara();
    },
	beforeMount() {
		this.loadingPage.show = true
		this.setDataSuaraPaslon();
		this.setDataSuaraWilayah();
		this.setScheduler();
	},
	mounted() {
		this.loadingPage.show = false
	},
	methods: {
		setScheduler(){
			let self = this
			setInterval(()=>{
				self.refreshData()
			}, 30000)
		},
		numberFormat(data){
			return numberFormat(data)
		},
		handleSuccess(data) {
			this.body.alertShow = true;
			this.body.alertType = this.$store.getters["app/color"]("s");
			this.body.alertMsg = success(data);
			setTimeout(() => { this.body.loading = false; }, 1000);
			setTimeout(() => { this.body.alertShow = false; }, 3000);
		},
		handleError(err) {
			this.body.alertShow = true;
			this.body.alertType = this.$store.getters["app/color"]("e");
			this.body.alertMsg = error(err);
			setTimeout(() => { this.body.loading = false; }, 1000);
			setTimeout(() => { this.body.alertShow = false; }, 3000);
		},
		// Function v-on update from chield (Alert & Dialog)
		bFunc_alertShow() {
			this.body.alertShow = false;
		},
		bFunc_dialogShow() {
			this.body.dialogShow = false;
		},
		hFunc_closePupUp() {
			this.body.showPopUp = false
            this.body.showDetail = false
			this.body.dataDetail = {}
		},
		backTingkatan(){
			this.loadingPage.show = true
			if(this.selectedArea.length>0){
				if(this.tingkatan=='Kelurahan'){
					// set & display data kecamatan
					this.selectedArea = []
					this.tingkatan=`Kecamatan`
					this.lblDaerah=``
				}else{
					// set & display data kelurahan
					let data = this.body.dataWilayah.filter(x=>x.kecID==this.selectedArea[0].kecID)
					this.selectedArea = data
					this.lblDaerah=`${this.selectedArea[0].kelName}`
					this.tingkatan=`Kelurahan`
				}
			}
			this.setDataSuaraPaslon();
			this.setDataSuaraWilayah();
			this.loadingPage.show = false
		},
		setTingkatan(id){
			this.loadingPage.show = true
			if(this.tingkatan=='Kecamatan'){
				// set & display data kelurahan
				this.selectedArea = this.body.dataWilayah.filter(x=>x.kecID==id)
				this.lblDaerah=`${this.selectedArea[0].kecName}`
				this.tingkatan=`Kelurahan`
				this.setDataSuaraPaslon();
				this.setDataSuaraWilayah();
				this.loadingPage.show = false
			}else if(this.tingkatan=='Kelurahan'){
				// set & display data TPS
				this.selectedArea = this.body.dataWilayah.filter(x=>x.kelID==id)
				this.lblDaerah=`${this.selectedArea[0].kelName} - ${this.selectedArea[0].kecName}`
				this.tingkatan=`TPS`
				this.setDataSuaraPaslon();
				this.setDataSuaraWilayah();
				this.loadingPage.show = false
			}else{
				// set & display laporan saksi
				this.body.dataDetail = this.body.dataWilayah.filter(x=>x.tpsID==id)[0]
				let dataWilayah = this.body.dataWilayah.filter(x=>x.tpsID==id)
				let dataSuara = this.body.dataSuara.filter(x=>x.iVotingPlaceID==id)
				this.body.dataDetail[`paslon`] = [...this.body.dataPaslon].map((x)=>{
					let iVoters = dataSuara.filter(i=>i.iCandidate==x.iCandidate).map(x=>x.iVoters).reduce((a, b) => a + b, 0)
					let dpt = dataWilayah.map(x=>x.dpt).reduce((a, b) => a + b, 0)
					let dptb = dataWilayah.map(x=>x.dptb).reduce((a, b) => a + b, 0)
					let sah = dataWilayah.map(x=>x.sah).reduce((a, b) => a + b, 0)
					let porsen = sah > 0 ? parseFloat(((iVoters/sah) * 100).toFixed(1)):0.0
					return {id:x.iCandidate, nourut:x.iSort, name1:x.sName1, name2:x.sName2, iVoters:numberFormat(iVoters), dpt:numberFormat(dpt), dptb:numberFormat(dptb), sah:numberFormat(sah), porsen:porsen}
				}).sort((a, b)=> a.iVoters > b.iVoters)
				this.body.showPopUp = true
            	this.body.showDetail = true
				this.loadingPage.show = false
			}
		},
		getDataPaslon() {
			if(localStorage.getItem('dataPaslon')==null){
				ValueRef.getDataPaslon().then(data => {
					if(data.status==true){
						localStorage.setItem('dataPaslon',JSON.stringify(data.data))
						this.body.dataPaslon = data.data;
						this.setDataSuaraPaslon()
					}else{
						this.handleError(data.message);
					}
				}).catch(err => { console.log('err getDataPaslon', err); this.handleError(err); })
			}else{
				this.body.dataPaslon = JSON.parse(localStorage.getItem('dataPaslon'))
			}
		},
		getDataWilayah() {
			if(localStorage.getItem('dataWilayah')==null){
				ValueRef.getDataWilayah().then(data => {
					if(data.status==true){
						localStorage.setItem('dataWilayah',JSON.stringify(data.data));
						this.body.dataWilayah = data.data;
						this.setDataSuaraWilayah();
					}else{
						this.handleError(data.message);
					}
				}).catch(err => { console.log('err getDataWilayah', err); this.handleError(err); })
			}else{
				this.body.dataWilayah = JSON.parse(localStorage.getItem('dataWilayah'))
			}
		},
		getDataSuara() {
			this.loadingPage.show = true
			ValueRef.getDataSuara().then(data => {
				if(data.status==true){
					this.body.dataSuara = data.data;
					this.setDataSuaraPaslon();
					this.setDataSuaraWilayah();
					this.loadingPage.show = false
				}else{
					this.handleError(data.message);
					this.loadingPage.show = false
				}
			}).catch(err => { console.log('err getDataSuara', err); this.handleError(err); })
		},
		setDataSuaraPaslon() {
			// filter data sesuai tingkatan
			let field = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?`kecID`:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?`kelID`:``
			let value = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?this.selectedArea[0].kecID:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?this.selectedArea[0].kelID:``
			let dataWilayah = this.selectedArea.length>0 ? [... this.body.dataWilayah.filter(x=>x[field]==value)] : [... this.body.dataWilayah]
			let activearea = dataWilayah.map(x=>x.tpsID) 
			let dataSuara = this.selectedArea.length>0 ? [... this.body.dataSuara.filter(x=>activearea.indexOf(x.iVotingPlaceID)>=0)] : [... this.body.dataSuara]
			// set data
			this.suaraPaslon = []
			this.suaraPaslon = [...this.body.dataPaslon].map((x)=>{
				let iVoters = dataSuara.filter(i=>i.iCandidate==x.iCandidate).map(x=>x.iVoters).reduce((a, b) => a + b, 0)
				let dpt = dataWilayah.map(x=>x.dpt).reduce((a, b) => a + b, 0)
				let dptb = dataWilayah.map(x=>x.dptb).reduce((a, b) => a + b, 0)
				let sah = dataWilayah.map(x=>x.sah).reduce((a, b) => a + b, 0)
				let notsah = dataWilayah.map(x=>x.notsah).reduce((a, b) => a + b, 0)
				let porsen = sah > 0 ? parseFloat((iVoters/sah) * 100).toFixed(1):0.0
				return {id:x.iCandidate, nourut:x.iSort, name1:x.sName1, name2:x.sName2, iVoters:iVoters, dpt:dpt, dptb:dptb, sah:sah, notsah:notsah, porsen:porsen}
			}).sort((a, b)=> a.iVoters > b.iVoters ? -1 : 1)
			let tpsVoted = dataWilayah.filter(x=>x.sah>0).length
			// this.tot_voters = this.suaraPaslon.length > 0 && this.suaraPaslon[0].sah > 0 ? parseFloat((this.suaraPaslon[0].sah+this.suaraPaslon[0].notsah)/(this.suaraPaslon[0].dpt+this.suaraPaslon[0].dptb)* 100).toFixed(1):0.0
			this.tot_voters = tpsVoted > 0 ? parseFloat((tpsVoted/dataWilayah.length)* 100).toFixed(1):0.0
		},
		setDataSuaraWilayah() {
			// filter data sesuai tingkatan
			let field = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?`kecID`:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?`kelID`:``
			let key = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?`kelID`:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?`tpsID`:`kecID`
			let name = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?`kelName`:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?`tpsName`:`kecName`
			let value = this.selectedArea.length>0&&this.tingkatan=='Kelurahan'?this.selectedArea[0].kecID:
				this.selectedArea.length>0&&this.tingkatan=='TPS'?this.selectedArea[0].kelID:``
			let dataWilayah = this.selectedArea.length>0 ? [... this.body.dataWilayah.filter(x=>x[field]==value)] : [... this.body.dataWilayah]
			let activearea = dataWilayah.map(x=>x.tpsID) 
			// suara RP
			let dataSuara = this.selectedArea.length>0 ? [... this.body.dataSuara.filter(x=>x.iCandidate==1&&activearea.indexOf(x.iVotingPlaceID)>=0)] : [... this.body.dataSuara.filter(x=>x.iCandidate==1)]
			// set data
			this.dataRekap = [];
			dataWilayah.reduce((res, value)=>{
				if (!res[value[key]]) {
					res[value[key]] = { id: value[key], nama: value[name], dpt:0, dptb:0, sah:0, notsah:0, porsen:0.0, rp:0 };
					this.dataRekap.push(res[value[key]])
				}
				res[value[key]].rp += dataSuara.filter(i=>i.iVotingPlaceID==value.tpsID).map(x=>x.iVoters).reduce((a, b) => a + b, 0)
				res[value[key]].dpt += value.dpt;
				res[value[key]].dptb += value.dptb;
				res[value[key]].sah += value.sah;
				res[value[key]].notsah += value.notsah;
				return res;
			}, {})
			this.dataRekap = this.dataRekap.map(x=>{
				x.porsen = x.sah > 0 ? parseFloat((x.rp/x.sah) * 100).toFixed(1) : 0.0
				return x
			})
		},
		refreshData(){
			this.loadingPage.show = true
			localStorage.removeItem('dataPaslon')
			localStorage.removeItem('dataWilayah')
			this.getDataPaslon();
			this.getDataWilayah();
			this.getDataSuara();
		}
	},
};
</script>

<style>
@media only screen and (max-width: 599px) {
	.homescreen {
		padding-right: 0 !important;
		padding-left: 0 !important;
	}
	.profile {
		margin-right: 0 !important;
	}

	.dotms-info {
		margin-right: 0 !important;
	}

}

.homescreen {
	padding-right: 4px;
	padding-left: 4px;
}

.profile {
	margin-right: 20px;
}

.profile h4 {
	font-size: 16px;
}

.profile p {
	font-size: 12px;
	color: #868D97;
}

.v-tooltip__content {
	background: white !important;
}

.v-tooltip__content .v-card {
	width: 110px;
}

.dotms-info {
	margin-right: 20px;
}

.profile .unit h5,
.profile .mill h5,
.profile .estate h5,
.dotms-info .need-services h5,
.dotms-info .in-services h5,
.dotms-info .services-overdue h5,
.dotms-info .operational h5,
.dotms-info .registered h5,
.dotms-info .request-update h5,
.dotms-info .request-mutation h5,
.dotms-info .expired h5 {
	font-weight: 400;
	font-size: 12px;
	color: #414042;
	height: 24px;
	line-height: 15px;
}

.profile .unit h3,
.profile .mill h3,
.profile .estate h3,
.dotms-info .need-services h3,
.dotms-info .in-services h3,
.dotms-info .services-overdue h3,
.dotms-info .operational h3,
.dotms-info .registered h3,
.dotms-info .request-update h3,
.dotms-info .request-mutation h3,
.dotms-info .expired h3 {
	font-weight: bold;
	font-size: 25px;
}

.profile .unit h3,
.dotms-info .services-overdue h3,
.dotms-info .request-mutation h3 {
	color: #00BAD1;
}

.profile .mill h3,
.dotms-info .in-services h3,
.dotms-info .request-update h3 {
	color: #FFB727;
}

.profile .estate h3,
.dotms-info .operational h3 {
	color: #15BF8C;
}

.dotms-info .need-services h3,
.dotms-info .expired h3 {
	color: #FF8186;
}

.profile .unit span,
.profile .unit .v-icon,
.profile .mill span,
.profile .mill .v-icon,
.profile .estate span,
.profile .estate .v-icon,
.dotms-info .need-services span,
.dotms-info .in-services span,
.dotms-info .services-overdue span,
.dotms-info .operational span {
	color: #15BF8C;
	border-radius: 5px;
	padding: 2px 5px 2px 2px;
	font-size: 12px !important;
}

.dotms-info .need-services span,
.dotms-info .in-services span,
.dotms-info .services-overdue span,
.dotms-info .operational span {
	background: #E3F9F2;
	padding: 0 5px;
}

.profile .unit span {
	background: #E3F9F2;
	cursor: pointer;
}

.profile .mill span {
	background: #FFE6E7;
	cursor: pointer;
}

.profile .estate span {
	background: #E3F9F2;
	cursor: pointer;
}

.notification {
	height: 321px;
}

.notification .v-list-item {
	min-height: 65px !important;
}

.application .icon-list-sidebar {
	background: linear-gradient(180deg, rgba(201, 28, 34, 0.15) 0%, rgba(217, 217, 217, 0) 100%) !important;
	width: 51.47px;
	height: 51.47px;
	margin: auto;
}

.application .icon-list-sidebar .v-image,
.application .icon-list-sidebar .v-icon {
	width: 35px;
	height: 25px;
	margin-top: 4px;
}

.v-slide-group:not(.v-slide-group--has-affixes)>.v-slide-group__prev,
.v-slide-group:not(.v-slide-group--has-affixes)>.v-slide-group__next {
	display: block;
}

.application .v-slide-group.v-item-group>.v-slide-group__next .v-icon,
.v-slide-group.v-item-group>.v-slide-group__prev .v-icon {
	width: 30px;
	height: 30px;
	border: 1px solid #DDDDDD;
	box-shadow: 0px 10px 10px rgba(227, 227, 227, 0.57);
	border-radius: 100%;
}

.application .v-list-item__icon {
	width: 100%;
}

.application .v-card {
	width: 100px;
}

.road-mapper .header-maps {
	position: inherit !important;
}

.road-mapper .searchMapper {
	margin-bottom: 20px;
}

.road-mapper .searchMapper .v-input__slot {
	padding: 8px !important;
}

.road-mapper .header-maps {
	margin: 0 !important;
}

#mapRoadScore {
	top: 190px !important;
	left: 16px !important;
	width: 97.8% !important;
	height: 77.5vh;
}

.road-mapper .header-maps {
	height: 90vh;
}
</style>